



































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { SSingle } from '@/inc/types'
import ShowroomAppointment from '@/components/Showroom/Appointment.vue'
import ShowroomInfo from '@/components/Showroom/Info.vue'
import ShowroomSlider from '@/components/Showroom/Slider.vue'
import UiPill from '@/components/ui/Pill.vue'

export default defineComponent({
  name: 'ShowroomSingle',
  components: {
    ShowroomAppointment,
    ShowroomInfo,
    ShowroomSlider,
    UiPill,
  },
  props: {
    popup: {
      type: Object as () => SSingle,
      required: false,
    },
  },
  setup(props) {
    const content = props.popup ? props.popup : useGetters(['content']).content
    const eventElRef = ref<HTMLElement | null>(null)

    // Scroll to event
    const onPillClick = () => {
      eventElRef.value?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }

    return {
      content,
      eventElRef,
      onPillClick,
    }
  },
})
